.RichEditorRoot {
  font-size: 14px;
}

.listItem {
  margin-left: 17px;
  padding-left: 4px;
}

.RichEditorEditor {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
  resize: none;
  font-size: 1.1rem;
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  border: 1px solid #30373d;
  border-top: none;
  background: #1A202C;
  color: #ECECF1;
  outline: none;
  max-height: 400px;
  overflow: auto;
}

.EditorReadOnly {
  color: rgba(255, 255, 255, 0.4);
  pointer-events: none;
}

.loadingwheel {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.generatebutton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: rgb(165, 162, 162);
  border: none;
  display: flex;
}

.generatebutton:hover {
  background: #1f2227;
}

.generatebutton:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background: none;
}

@keyframes wave {

  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}

.dot {
  animation: wave 1.3s linear infinite;
  display: inline-block;
  margin-left: 4px;
}

.dot:nth-child(2) {
  animation-delay: -1.1s;
}

.dot:nth-child(3) {
  animation-delay: -0.9s;
}

@media (max-width: 768px) {
  .RichEditorEditor {
    border-radius: 1rem;
    border-top: 1px;
    border: 1px solid #30373d;
    transition: border-radius 0.2s, border-top 0.5s, border 0.5s;
  }

  .RichEditorEditorFocused {
    border-top: none;
    border-radius: 0 0 1rem 1rem;
    transition: border-radius 0.3s, border-top 0.3s, border 0.3s;
  }
}