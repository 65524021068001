.main {
  justify-content: center;
}

.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  width: auto;
}

.header p {
  text-align: center;
}

.topnav {
  background-color: #1d2333;
  border-bottom: 1px solid #30373d;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.75rem 1rem 0.75rem;
  align-items: center;
}

.navlogo, .navlinks a {
  font-weight: 500;
}

.navlogo {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.navlinks {
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.apptitle {
  font-size: 2.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.appdescription {
  font-size: 1.1rem;
  margin: 1rem;
}

.link {
  font-weight: 500;
}

.cloudform {
  position: relative;
  width: 100%;
  z-index: 999;
  flex-direction: column-reverse;
  margin-bottom: 50px;
}

.textarea {
  position: relative;
  resize: none;
  font-size: 1.1rem;
  padding: 1.2rem 2rem 1.2rem 2rem;
  width: 100%;
  border-radius: 1.5rem;
  border: 1px solid #30373d;
  background: #1A202C;
  color: #ECECF1;
  outline: none;
}

.textarea:disabled {
  opacity: 0.5;
}

.textarea::placeholder {
  color: #5f6368;
}

.generatebutton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: rgb(165, 162, 162);
  background: none;
  padding: 1rem;
  border: none;
  display: flex;
}

.loadingwheel {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.svgicon {
  transform: rotate(90deg);
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}

.generatebutton:hover {
  background: #1f2227;
  border-radius: 1rem;
}

.generatebutton:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background: none;
}

.messagelist {
  width: 100%;
  max-height: calc(100vh); 
  flex-grow: 1; 
}

.messagelist > :last-child {
  padding-bottom: 200px;
}

.messagelistloading {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.usermessage {
  padding: 1em 1.5rem 0.5em 1.5rem;
  color: #ECECF1;
  overflow-x: hidden;
}

.usermessagewaiting{
  padding: 1.5rem;
  color: #ECECF1;
  background: linear-gradient(to left, #1A202C, #172036, #1A202C);
  background-size: 200% 200%;
  background-position: -100% 0;
  animation: loading-gradient 2s ease-in-out infinite;
  animation-direction: alternate;
  animation-name: loading-gradient;
}

@keyframes loading-gradient {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.apimessage {
  padding: 1.5rem;
  animation: fadein 0.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.apimessage, .usermessage, .usermessagewaiting {
  display: flex;
  gap: 10px;
}

.messagebutton {
  background: white;
  border: none;
  color: black;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
  outline: none;
}

.markdown {
  line-height: 1.75;
  width: 100%;
}

.markdown a:hover {
  opacity: 0.8;
}

.markdown a {
  color: #16bed7;
  font-weight: 500;
}

.markdown code {
  margin: 20px 0;
  width: 100%;
  font-size: 0.9em;
}

.markdown p:not(:first-child) {
  margin: 15px 0;
}

.markdown p code {
  font-weight: 900;
  margin: 0 5px;
  color: #fff;
}


.markdown ol, .markdown ul {
  margin: 1rem;
}

.boticon, .usericon {
  margin-right: 1rem;
  border-radius: 0.1rem;
}

.markdown h1, .markdown h2, .markdown h3 {
  font-size: inherit;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 100%;
}

.cloud {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointsnormal {
  width: 90%;
  height: 90%;
}

.pointsdim {
  width: 90%;
  height: 90%;
  opacity: 0.25;
}

.footer {
  color: #5f6368;
  font-size: 0.8rem;
  margin: 1.5rem;
}

.footer a {
  font-weight: 500;
  color: #7a7d81;
}

.footer a:hover {
  opacity: 0.8;
}

/* Mobile optimization */
@media (max-width: 600px) {

  .main {
    padding: 1rem;
    max-height: 90vh;
  }

  .cloud {
    width: 22rem;
    height: 28rem;
  }
  .textarea {
    width: 22rem;
    max-height: 200px;
  }
  .topnav {
    border: 1px solid black;
    align-items: center;
    padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  }

.navlogo {
  font-size: 1.25rem;
  width: 20rem;
}

.markdown code {
  white-space : pre-wrap !important;
}

.footer {
  font-size: 0.7rem;
  width: 100%;
  text-align: center;
}
}

.componentMessage {
  display: flex;
  align-items: center;
}

@media (min-width: 769px) {
  .chat {
    margin-left: 50px;
  }

  .messagelist > :last-child {
    padding-bottom: 170px;
  }

  .cloudform {
    margin-bottom: 0px;
  }
}